<template>
  <v-form ref="formPermission" lazy-validation>
    <v-row>
      <v-col cols="12" :sm="isAdmin ? 6 : 12">
        <v-autocomplete
          v-model="bodyRequest.leave"
          :items="listPermission"
          :label="$t('app.select') + ' ' + $t('permission.permission_type')"
          :rules="[v => !!v || $t('app.required')]"
          :loading="loading"
          :item-text="item => (g_language === 'en' ? item.en : item.idn)"
          item-value="id"
          outlined
          dense
        />
      </v-col>
      <v-col v-if="isAdmin" cols="12" sm="6">
        <v-autocomplete
          v-model="bodyRequest.person"
          :items="dataEmployee"
          :label="
            $t('app.select') +
              ' ' +
              (permissionType == 'TEACHER'
                ? $t('app.teacher')
                : $t('app.staff'))
          "
          :rules="[v => !!v.length > 0 || $t('app.required')]"
          :loading="loadingEmployee"
          multiple
          item-value="person"
          item-text="name"
          outlined
          dense
        >
          <template slot="prepend-item">
            <v-list-item @click="handleClickAll">
              <v-list-item-action>
                <v-checkbox :value="selectAllEmployee" />
              </v-list-item-action>
              <v-list-item-content
                :class="selectAllEmployee && 'primary--text'"
              >
                {{ $t("app.select_all") }}
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item.name }}</span>
            <span v-if="index === 1" class="grey--text">
              (+{{ bodyRequest.person.length - 1 }} others)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6">
        <DatePicker
          :label="$t('permission.start_date')"
          :rules="[v => !!v || $t('app.required')]"
          :value="bodyRequest.date_in"
          outlined
          dense
          @getValue="
            v => {
              bodyRequest.date_in = v;
              bodyRequest.date_out = null;
              $refs.formPermission.resetValidation();
            }
          "
        />
      </v-col>
      <v-col cols="12" sm="6">
        <DatePicker
          :label="$t('permission.end_date')"
          :rules="[v => !!v || $t('app.required')]"
          :minDate="bodyRequest.date_in"
          :disabled="!bodyRequest.date_in"
          :value="bodyRequest.date_out"
          outlined
          dense
          @getValue="v => (bodyRequest.date_out = v)"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="bodyRequest.desc"
          :label="$t('permission.description')"
          :rules="[v => !!v || $t('app.required')]"
          dense
          auto-grow
          outlined
        />
      </v-col>
      <v-col cols="12">
        <v-file-input
          v-model="bodyRequest.temp_file"
          :label="$t('permission.attachment')"
          accept="image/*"
          prepend-icon
          prepend-inner-icon="mdi-paperclip"
          outlined
          dense
          clearable
          small-chips
          chips
          color="primary"
        >
          <template v-slot:selection="{ text }">
            <v-chip small label color="primary">{{ text }}</v-chip>
          </template>
        </v-file-input>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    bodyRequest: Object,
    listPermission: Array,
    loading: Boolean,
    checkValidate: Boolean,
    reset: Boolean,
    isAdmin: Boolean,
    permissionType: String,
    // dataEmployee filled if isAdmin = true
    dataEmployee: Array,
    loadingEmployee: Boolean
  },
  computed: {
    ...mapGetters(["g_language"])
  },
  watch: {
    checkValidate(value) {
      if (value) {
        this.$refs.formPermission.validate();
      }
    },
    reset(value) {
      if (value) {
        this.selectAllEmployee = false;
        this.$refs.formPermission.reset();
        this.$refs.formPermission.resetValidation();
      }
    }
  },
  components: {
    DatePicker: () => import("@/components/DatePicker")
  },
  data() {
    return {
      selectAllEmployee: false
    };
  },
  methods: {
    handleClickAll() {
      this.selectAllEmployee = !this.selectAllEmployee;
      this.$emit("selectAll", []);
      if (this.selectAllEmployee) {
        this.$emit(
          "selectAll",
          this.dataEmployee.map(item => item.person)
        );
      } else {
        this.selectAllEmployee = false;
      }
    }
  }
};
</script>
