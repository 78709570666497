var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"formPermission",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":_vm.isAdmin ? 6 : 12}},[_c('v-autocomplete',{attrs:{"items":_vm.listPermission,"label":_vm.$t('app.select') + ' ' + _vm.$t('permission.permission_type'),"rules":[v => !!v || _vm.$t('app.required')],"loading":_vm.loading,"item-text":item => (_vm.g_language === 'en' ? item.en : item.idn),"item-value":"id","outlined":"","dense":""},model:{value:(_vm.bodyRequest.leave),callback:function ($$v) {_vm.$set(_vm.bodyRequest, "leave", $$v)},expression:"bodyRequest.leave"}})],1),(_vm.isAdmin)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.dataEmployee,"label":_vm.$t('app.select') +
            ' ' +
            (_vm.permissionType == 'TEACHER'
              ? _vm.$t('app.teacher')
              : _vm.$t('app.staff')),"rules":[v => !!v.length > 0 || _vm.$t('app.required')],"loading":_vm.loadingEmployee,"multiple":"","item-value":"person","item-text":"name","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text"},[_vm._v(" (+"+_vm._s(_vm.bodyRequest.person.length - 1)+" others) ")]):_vm._e()]}}],null,false,706964724),model:{value:(_vm.bodyRequest.person),callback:function ($$v) {_vm.$set(_vm.bodyRequest, "person", $$v)},expression:"bodyRequest.person"}},[_c('template',{slot:"prepend-item"},[_c('v-list-item',{on:{"click":_vm.handleClickAll}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"value":_vm.selectAllEmployee}})],1),_c('v-list-item-content',{class:_vm.selectAllEmployee && 'primary--text'},[_vm._v(" "+_vm._s(_vm.$t("app.select_all"))+" ")])],1)],1)],2)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('DatePicker',{attrs:{"label":_vm.$t('permission.start_date'),"rules":[v => !!v || _vm.$t('app.required')],"value":_vm.bodyRequest.date_in,"outlined":"","dense":""},on:{"getValue":v => {
            _vm.bodyRequest.date_in = v;
            _vm.bodyRequest.date_out = null;
            _vm.$refs.formPermission.resetValidation();
          }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('DatePicker',{attrs:{"label":_vm.$t('permission.end_date'),"rules":[v => !!v || _vm.$t('app.required')],"minDate":_vm.bodyRequest.date_in,"disabled":!_vm.bodyRequest.date_in,"value":_vm.bodyRequest.date_out,"outlined":"","dense":""},on:{"getValue":v => (_vm.bodyRequest.date_out = v)}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('permission.description'),"rules":[v => !!v || _vm.$t('app.required')],"dense":"","auto-grow":"","outlined":""},model:{value:(_vm.bodyRequest.desc),callback:function ($$v) {_vm.$set(_vm.bodyRequest, "desc", $$v)},expression:"bodyRequest.desc"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"label":_vm.$t('permission.attachment'),"accept":"image/*","prepend-icon":"","prepend-inner-icon":"mdi-paperclip","outlined":"","dense":"","clearable":"","small-chips":"","chips":"","color":"primary"},scopedSlots:_vm._u([{key:"selection",fn:function({ text }){return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}]),model:{value:(_vm.bodyRequest.temp_file),callback:function ($$v) {_vm.$set(_vm.bodyRequest, "temp_file", $$v)},expression:"bodyRequest.temp_file"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }